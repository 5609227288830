import Settings from "../helpers/constants";

export default function GetToken() {
  return sessionStorage.getItem(
    `oidc.user:${Settings().authority}:${
      localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
    }`
  )
    ? //@ts-ignore
      JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${Settings().authority}:${
            localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
          }`
        )
      ).access_token
    : "No Token";
}

export function GetRefreshToken() {
  return sessionStorage.getItem(
    `oidc.user:${Settings().authority}:${
      localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
    }`
  )
    ? //@ts-ignore
      JSON.parse(
        sessionStorage.getItem(
          `oidc.user:${Settings().authority}:${
            localStorage.getItem("ala:MppLaunch") === "true" ? Settings().mpp_user_client_id : Settings().client_id
          }`
        )
      ).refresh_token
    : "No Token";
}
