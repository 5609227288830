// @ts-ignore
import { WindowAPPENVprops } from "../root-component.props";
declare global {
  interface Window {
    APP_ENV: WindowAPPENVprops;
  }
}

export default function Consts() {
  if (window.location.host === "localhost:3000") {
    return {
      authority: window.APP_ENV.ala_authority,
      client_id: window.APP_ENV.ala_client_id,
      mpp_user_client_id: window.APP_ENV.mpp_user_client_id,
      redirect_uri: window.APP_ENV.ala_redirect_uri,
      post_logout_redirect_uri: window.APP_ENV.ala_post_logout_redirect_uri,
      response_type: window.APP_ENV.ala_response_type,
      scope: window.APP_ENV.ala_scope
    };
  } else {
    return {
      authority: window.APP_ENV.authority,
      client_id:
        window.location.host === window.APP_ENV.WneUserVanityURL
          ? window.APP_ENV.WNE_client_id
          : window.APP_ENV.client_id,
      mpp_user_client_id: window.APP_ENV.mpp_user_client_id,
      redirect_uri:
        window.location.host === window.APP_ENV.WneUserVanityURL
          ? window.APP_ENV.WNE_redirect_uri
          : window.APP_ENV.redirect_uri,
      post_logout_redirect_uri:
        window.location.host === window.APP_ENV.WneUserVanityURL
          ? window.APP_ENV.WNE_post_logout_redirect_uri
          : window.APP_ENV.post_logout_redirect_uri,
      response_type: window.APP_ENV.response_type,
      scope: window.APP_ENV.scope
    };
  }
}
